import React, { useState } from "react";
import { Nav, StaticButton } from "../Constant";
import { Footer } from "../UI";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium text-left">{title}</p>
        <svg
          viewBox="0 0 24 24"
          className={`w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="2,7 12,17 22,7"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

export default function Faqs() {
  return (
    <>
      <main>
        <Nav />

        {/* Hero */}
        <div className="bg-mainColor">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
            <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
              <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                  <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                    Frequently Asked Questions
                  </h2>
                  {/* <p className="text-base text-indigo-100 md:text-lg">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque rem aperiam, eaque ipsa quae.
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Accordion */}
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
          <div class="max-w-xl sm:mx-auto lg:max-w-7xl">
            <div class="space-y-4">
              <Item title="What is Yiieldy technology, and how does it relate to agriculture?">
                Yiieldy technology involves utilizing sensors, IoT devices, and
                data analytics in agriculture to monitor and analyze various
                parameters such as soil conditions, weather, and crop health. It
                helps farmers make data-driven decisions, optimizing farming
                processes and improving yields
              </Item>
              <Item title="How does your company use artificial intelligence to play a role in modern agriculture through Yiieldy technology?">
                We employ the use of AI which processes the data collected by
                Yiieldy technology to derive meaningful insights. Our AI
                algorithms analyze the data to provide predictions, optimize
                irrigation, detect diseases, recommend crop rotations, and offer
                real-time monitoring, ultimately enhancing farming efficiency
                and productivity.
              </Item>
              <Item title="What are the primary benefits of using our AI-powered Yiieldy technology for farmers?">
                The benefits include precise resource management, enhanced crop
                yield, reduced resource wastage, early disease detection,
                optimized irrigation, efficient pest management, and informed
                decision-making based on real-time data, leading to increased
                profitability for farmers.
              </Item>
              <Item title="Can you explain how your Yiieldy technology will help optimize resource usage and increase efficiency in my farming?">
                Using our Yiieldy technology, we collect data on soil moisture,
                nutrient levels, and weather conditions. Our AI then processes
                this data to optimize resource usage, ensuring appropriate
                irrigation and fertilizer application. This data-driven approach
                enhances farming efficiency and minimizes resource wastage.
              </Item>
              <Item title="Are there specific types of sensors or devices associated with your Yiieldy technology?">
                Yes, our Yiieldy technology utilizes various sensors such as
                soil moisture sensors, weather stations, drones, satellite
                imagery, GPS devices, and IoT-enabled equipment to collect
                relevant agricultural data.
              </Item>
              <Item title="How does your Yiieldy technology aid in monitoring soil health and nutrient levels in our crops?">
                Our Yiieldy technology employs soil sensors to measure moisture,
                pH levels, and nutrient content while the AI algorithms analyze
                this data to provide insights into soil health and nutrient
                levels, enabling you to make informed decisions about fertilizer
                application and land management.
              </Item>
              <Item title="Are there AI algorithms used to predict crop growth and yield based on Yiieldy data?">
                Absolutely, AI algorithms process Yiieldy data to predict crop
                growth stages, yield estimates, and harvest times. These
                predictions assist farmers in planning and optimizing their
                harvests for maximum yield.
              </Item>
              <Item
                title={
                  "How can we as farmers access and interpret the data collected through Yiieldy technology?"
                }
              >
                As farmers, you can access the data through user-friendly
                interfaces, mobile apps, or web platforms. The data is presented
                in a comprehensible format, and AI-generated insights are
                provided to help you interpret and utilize the information
                effectively.
              </Item>
              <Item
                title={
                  "How does the Yiieldy technology ensure data privacy and security for farmers?"
                }
              >
                Data privacy and security are maintained through encryption,
                restricted access, compliance with privacy regulations, and
                secure storage mechanisms. The technology follows best practices
                to safeguard sensitive agricultural data.
              </Item>
              <Item
                title={
                  "What are the key considerations for farmers before implementing Yiieldy technology on their farms?"
                }
              >
                Key considerations include understanding the specific needs of
                the farm, budget allocation, data management strategies,
                integration with existing systems, and assessing the scalability
                and compatibility of the technology with the farm's future
                plans. Farmers should also consider the level of technical
                support and training provided by the technology provider.
              </Item>
              <Item
                title={
                  "I am into small-scale farming. Is Yiieldy technology compatible with different types and scales of agriculture, including small-scale farming?"
                }
              >
                Yes, Yiieldy technology is designed to cater to various types
                and scales of agriculture, from small-scale farms to large
                agricultural operations. The technology is adaptable and can be
                customized to suit the specific needs of different farming
                setups.
              </Item>
            </div>
          </div>
        </div>

        <div className="bg-mainColor">
          <Footer />
          <StaticButton />
        </div>
      </main>
    </>
  );
}
