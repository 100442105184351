import React from "react";
import { Nav, StaticButton } from "../Constant";
import { Footer } from "../UI";

export default function Privacy() {
  return (
    <>
      <main>
        <Nav />

        {/* Hero */}
        <div className="bg-mainColor">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-40">
            <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
              <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                  {/* <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                    Privacy Policy
                  </h2> */}
                  {/* <p className="text-base text-indigo-100 md:text-lg">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque rem aperiam, eaque ipsa quae.
        </p> */}
                  <div className="mb-4 lg:mb-0">
                    <h1 className="mb-4 lg:text-4xl text-white font-bold leading-none sm:text-2xl text-center">
                      Welcome To LogicGraph Privacy Policys
                    </h1>
                    <div className="relative">
                      <p className="text-base text-gray-400 md:text-lg text-center">
                        Welcome to our Privacy Policy. This document explains
                        how we collect, use, disclose, and protect your personal
                        information when you use our services. We are committed
                        to respecting your privacy and ensuring the
                        confidentiality and security of your personal
                        information.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Body */}
        <div
          className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-7xl md:px-24 lg:px-8 lg:py-20"
          id="privacy"
        >
          <div className="max-w-screen-lg sm:mx-auto">
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row"></div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Information We Collect:
                </h2>
                <div className="relative">
                  <p>
                    We may collect various types of information, including but
                    not limited to:
                  </p>
                  <ul className="list-disc px-5" id="priv-ul">
                    <li>
                      Personal Information: Name, contact information, email
                      address, etc.
                    </li>
                    <li>
                      Usage Information: Data about your interaction with our
                      services, including log data and analytics.
                    </li>
                    <li>
                      Cookies and Tracking: Information obtained through cookies
                      and similar technologies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  How We Use Your Information:
                </h2>
                <div className="relative">
                  <p>
                    We use the collected information for the following purposes:
                  </p>
                  <ul className="list-disc px-5" id="priv-ul">
                    <li>Provide and maintain our services.</li>
                    <li>Personalize and enhance your experience.</li>
                    <li>Communicate with you and respond to inquiries.</li>
                    <li>Monitor and analyze usage patterns and trends.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Data Sharing and Disclosure:
                </h2>
                <div className="relative">
                  <p>
                    We may share your information with third parties in the
                    following circumstances:
                  </p>
                  <ul className="list-disc px-5" id="priv-ul">
                    <li>With your consent.</li>
                    <li>To comply with legal obligations.</li>
                    <li>For legitimate business purposes.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Data Security:
                </h2>
                <p>
                  We implement appropriate security measures to protect your
                  personal information from unauthorized access, alteration,
                  disclosure, or destruction.:
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Your Rights and Choices:
                </h2>
                <div className="relative">
                  <p>You have the right to:</p>
                  <ul className="list-disc px-5" id="priv-ul">
                    <li>Access and correct your personal information.</li>
                    <li>Opt-out of receiving communications from us.</li>
                    <li>Request the deletion of your personal information.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Third-Party Links:
                </h2>
                <p>
                  Our services may contain links to third-party websites. We are
                  not responsible for the content or privacy practices of these
                  websites.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Changes to this Privacy Policy:
                </h2>
                <p>
                  We may update this Privacy Policy periodically. Any changes
                  will be posted on this page, and the effective date will be
                  updated accordingly.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
              <div className="mb-4 lg:mb-0">
                <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                  Contact Us:
                </h2>
                <div className="relative">
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy, please contact us at:
                  </p>
                  <ul className="list-disc px-5" id="priv-ul">
                    <li>Tel: +(234)8055316857</li>
                    <li>E-mail: contactus@logicgraph.net</li>
                  </ul>
                  <p>
                    By using our services, you consent to the terms outlined in
                    this Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="bg-mainColor">
          <Footer />
          <StaticButton />
        </div>
      </main>
    </>
  );
}
