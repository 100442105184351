import React from "react";
import { Nav } from "../Constant";
import { Footer } from "../UI";

export default function Terms() {
  return (
    <>
      <main>
        <Nav />

        {/* Hero */}
        <div className="bg-mainColor">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
            <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
              <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                  <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                    Welcome To LogicGraph Terms & Conditions
                  </h2>
                  {/* <p className="text-base text-indigo-100 md:text-lg">
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
    accusantium doloremque rem aperiam, eaque ipsa quae.
  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Body */}
        <div className="px-8 py-6 flex flex-col gap-3" id="terms">
          <section className="flex flex-col gap-3">
            {/* <h1 className="text-2xl">Terms and Conditions</h1> */}
            <p className="text-gray-500">Last updated: (13/11/2023)</p>
            These Terms and Conditions outline the rules and regulations for the
            use of our website. By accessing this website, we assume you accept
            these terms and conditions. Do not continue to use LogicGraph
            website if you do not agree to take all of the terms and conditions
            stated on this page. The following terminology applies to these
            Terms and Conditions, Privacy Statement, and Disclaimer Notice and
            all agreements: "Client," "You," and "Your" refers to you, the
            person log on this website and compliant to the Company’s terms and
            conditions. "The Company," "Ourselves," "We," "Our," and "Us" refers
            to our Company. "Party," "Parties," or "Us," refers to both the
            Client and ourselves. All terms refer to the offer, acceptance, and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of the United States. Any use of the
            above terminology or other words in the singular, plural,
            capitalization, and/or he/she or they, are taken as interchangeable
            and therefore as referring to same.
          </section>
          <main className="flex flex-col gap-3">
            <ol className="list-decimal px-5 flex flex-col gap-10">
              <li className="text-xl">
                Cookies
                <div className="flex flex-col gap-3 mt-2">
                  <p className="text-[13px] text-justify">
                    We employ the use of cookies. By accessing LogicGraph
                    Website, you agreed to use cookies in agreement with
                    LogicGraph Website's Privacy Policy.
                  </p>
                  <p className="text-[13px] text-justify">
                    Most interactive websites use cookies to enable us to
                    retrieve user details for each visit. Cookies are used by
                    our website to enable the functionality of certain areas to
                    make it easier for people visiting our website. Some of our
                    affiliate/advertising partners may also use cookies.
                  </p>
                </div>
              </li>
              <li className="text-xl">
                License
                <div className="flex flex-col gap-3 mt-2">
                  <p className="text-[13px] text-justify">
                    Unless otherwise stated, LogicGraph Website and/or its
                    licensors own the intellectual property rights for all
                    material on LogicGraph Website. All intellectual property
                    rights are reserved. You may access this from LogicGraph
                    Website for your own personal use subjected to restrictions
                    set in these terms and conditions.
                  </p>
                </div>
              </li>
              <li className="text-xl">
                You must not:
                <div className="flex flex-col gap-3 mt-2">
                  <p className="text-[13px] text-justify">
                    Republish material from LogicGraph Website Sell, rent, or
                    sub-license material from LogicGraph Website Reproduce,
                    duplicate or copy material from LogicGraph Website,
                    Redistribute content from LogicGraph Website. This Agreement
                    shall begin on the date hereof.
                  </p>
                  <p className="text-[13px] text-justify">
                    Parts of this website offer an opportunity for users to post
                    and exchange opinions and information in certain areas of
                    the website. LogicGraph Website does not filter, edit,
                    publish, or review Comments prior to their presence on the
                    website. Comments do not reflect the views and opinions of
                    LogicGraph Website, its agents, and/or affiliates. Comments
                    reflect the views and opinions of the person who posts their
                    views and opinions. To the extent permitted by applicable
                    laws, LogicGraph Website shall not be liable for the
                    Comments or for any liability, damages, or expenses caused
                    and/or suffered as a result of any use of and/or posting of
                    and/or appearance of the Comments on this website.
                  </p>
                </div>
              </li>
              <li className="text-xl">
                Products/Solutions
                <div className="flex flex-col gap-3 mt-2">
                  <ul className="list-disc">
                    <li>
                      <a
                        className="text-[13px] text-justify text-mainColor underline font-bold"
                        href="https://yiieldy.com"
                        target="_blank"
                      >
                        Yiieldy
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ol>
          </main>
          <div className="flex flex-col items-start py-4 transform rounded sm:px-4 lg:flex-row">
            <div className="mb-4 lg:mb-0">
              <h2 className="mb-4 text-xl font-bold leading-none sm:text-2xl">
                Contact Us:
              </h2>
              <div className="relative">
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at:
                </p>
                <ul className="list-disc px-5" id="priv-ul">
                  <li>Tel: +(234)8055316857</li>
                  <li>E-mail: contactus@logicgraph.net</li>
                </ul>
                <p>
                  By using our services, you consent to the terms outlined in
                  this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-mainColor">
          <Footer />
        </div>
      </main>
    </>
  );
}
