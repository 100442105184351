import React from 'react'
import { Logo } from '../Assets'

export default function ComingSoon() {
  return (
    <main className='bg-black h-screen flex flex-col justify-center items-center gap-4'>
        <img src={Logo} alt='LogicGraph' className='w-[200px]'/>
        <h1 className='text-gray-400 lg:text-7xl text-3xl'>Coming Soon....</h1>
    </main>
  )
}
